import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

import { formatPhoneNumber, getPhoneNumberHref } from 'utils/helpers';
import { headerHeight, mobileHeaderHeight } from 'components/theme/measurements';
import Box from 'components/Box';
import Flex from 'components/Flex';
import Grid from 'components/Grid';
import Hamburger from 'components/layout/Hamburger';
import HeaderLinkHoverWrap from 'components/layout/HeaderLinkHoverWrap';
import Link, { LinkProps } from 'components/Link';
import LinkButton from 'components/LinkButton';
import SocialLinks from 'components/SocialLinks';

const employmentLinks = [
  {
    title: 'For Employees',
    subtitle: 'Find out how we defend your rights as an employee in Ontario',
    link: '/employment-law/employees',
  },
  {
    title: 'For Employers',
    subtitle: 'Learn about our advice for employers to manage your risk and your workers',
    link: '/employment-law/employers',
  },
];

const HeaderLink: React.FC<LinkProps> = (props) => {
  return (
    <Flex alignItems="center" justifyContent="center" height={[40, 'auto']}>
      <Link
        activeClassName="active"
        variant="header6Serif"
        noDecorate
        sx={{
          borderBottom: '2px solid',
          borderColor: 'transparent',
          '&:hover, &.active': { borderColor: 'gold' },
        }}
        {...props}
      />
    </Flex>
  );
};

const HeaderQuery = graphql`
  query HeaderQuery {
    logo: file(relativePath: { eq: "logo-wordmark.png" }) {
      childImageSharp {
        gatsbyImageData(height: 70, placeholder: NONE, layout: FIXED)
      }
    }
    smallLogo: file(relativePath: { eq: "logo-wordmark.png" }) {
      childImageSharp {
        gatsbyImageData(height: 40, placeholder: NONE, layout: FIXED)
      }
    }
    sanityCompanyDetails(_id: { eq: "companyDetails" }) {
      phoneNumber
    }
  }
`;

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const {
    logo,
    smallLogo,
    sanityCompanyDetails: { phoneNumber },
  } = useStaticQuery(HeaderQuery);
  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY <= 0) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
    }, 100);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  return (
    <Box
      as="header"
      height={[mobileHeaderHeight, mobileHeaderHeight, headerHeight]}
      width="100%"
      bg="white"
      color="black"
      position="fixed"
      top={0}
      left={0}
      zIndex={2}
      px={5}
      sx={{ boxShadow: scrolled ? 'light' : 'none', transition: 'box-shadow 200ms ease' }}
    >
      <Box
        as="nav"
        display="flex"
        alignItems="center"
        justifyContent={{ _: 'flex-end', md: 'center' }}
        height="100%"
      >
        <Link
          to="/"
          mr={8}
          position="absolute"
          left={5}
          top="50%"
          sx={{
            transform: 'translateY(-50%)',
            visibility: ['hidden', 'hidden', 'visible'],
          }}
        >
          <GatsbyImage
            image={logo.childImageSharp.gatsbyImageData}
            className="logo"
            alt="PLG Perks Law Group Logo"
          />
        </Link>
        <Link
          to="/"
          mr={8}
          position="absolute"
          left={5}
          top="50%"
          sx={{
            transform: 'translateY(-50%)',
            visibility: ['visible', 'visible', 'hidden'],
          }}
        >
          <GatsbyImage
            image={smallLogo.childImageSharp.gatsbyImageData}
            className="small-logo"
            alt="PLG Perks Law Group Logo Small"
          />
        </Link>
        <Hamburger
          role="button"
          tabIndex={0}
          onClick={() => setOpen((o) => !o)}
          active={open}
          display={['inline', 'none']}
          ml="auto"
          sx={{ cursor: 'pointer' }}
        />

        <Grid
          gridAutoColumns={['auto']}
          gridAutoFlow={['row', 'column', 'column']}
          gridTemplateRows={['none', 'none', 'auto auto', 'auto']}
          gridColumnGap={[0, 6]}
          gridRowGap={[0, 3]}
          display={[open ? 'grid' : 'none', 'grid']}
          position={['absolute', 'static']}
          top={mobileHeaderHeight}
          left={0}
          width={['100%', 'auto']}
          bg="white"
          borderBottom={['2px solid', 'none']}
          borderColor={['gold', 'transparent']}
        >
          <HeaderLink to="/about/" ml={0}>
            About Us
          </HeaderLink>
          <HeaderLinkHoverWrap label="Employment Law" links={employmentLinks}></HeaderLinkHoverWrap>
          <HeaderLink to="/business-law/">Business Law</HeaderLink>
          <HeaderLink to="/resources/">Resources</HeaderLink>
          <HeaderLink to="/pay/">Pay</HeaderLink>
          <HeaderLink to="/contact/">Contact</HeaderLink>
          <SocialLinks ml={2} display={['flex', 'none']} justifyContent="center" height={40} />
          <LinkButton
            to="/contact"
            display={['inline', 'none']}
            bVariant="tertiary"
            textAlign="center"
            mt={2}
          >
            Request a consultation
          </LinkButton>
        </Grid>
        <Box
          display={{ _: 'none', md: 'flex' }}
          flexDirection="column"
          flex="0 0 236px"
          alignItems="center"
          position={{ _: 'static', md: 'absolute' }}
          ml={6}
          right={{ _: 0, md: 5 }}
          top="50%"
          sx={{ transform: ['none', 'none', 'translateY(-50%)'] }}
        >
          <Flex>
            <Box
              as="a"
              variant="body1"
              href={getPhoneNumberHref(phoneNumber)}
              sx={{ textDecoration: 'none' }}
              mr={1}
            >
              {formatPhoneNumber(phoneNumber)}
            </Box>
            <SocialLinks ml={2} />
          </Flex>
          <LinkButton to="/contact" bVariant="tertiary" mt={2}>
            Request a consultation
          </LinkButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
