import React from 'react';

import Box, { BoxProps } from 'components/Box';
import Flex, { FlexProps } from 'components/Flex';
import * as SocialIcons from 'components/SocialIcons';

const SocialLink: React.FC<BoxProps & { href: string }> = ({ href, ...props }) => {
  return (
    <Box
      as="a"
      href={href}
      p={1}
      bg="darkGold"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      border="1px solid"
      borderColor="darkGold"
      sx={{
        '&:hover, &:focus': {
          bg: 'white',
          color: 'darkGold',
        },
      }}
      {...props}
    />
  );
};

const SocialLinks: React.FC<FlexProps> = (props) => {
  return (
    <Flex {...props}>
      <SocialLink href="https://linkedin.com/company/perks-law-group">
        <SocialIcons.LinkedIn />
      </SocialLink>
      <SocialLink href="https://facebook.com/perkslawgroup" ml={1}>
        <SocialIcons.Facebook />
      </SocialLink>
      <SocialLink href="https://twitter.com/perkslawgroup" ml={1}>
        <SocialIcons.Twitter />
      </SocialLink>
    </Flex>
  );
};

export default SocialLinks;
