export function toPlainText(blocks) {
  if (!blocks) {
    return '';
  }
  return blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      return block.children.map((child) => child.text).join('');
    })
    .join('\n\n');
}

export function stripHeadings(blocks) {
  if (!blocks) return blocks;
  return blocks.filter((block) => !['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(block.style));
}

export function getPhoneNumberHref(phoneNumber) {
  if (!phoneNumber) return '';
  const phoneString = String(phoneNumber);
  return `tel:+1${phoneString}`;
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';
  const phoneString = String(phoneNumber);
  return `(${phoneString.slice(0, 3)}) ${phoneString.slice(3, 6)}-${phoneString.slice(6)}`;
}
