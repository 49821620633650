import React, { useEffect, useState } from 'react';

import { shadows } from 'components/theme';
import Box, { BoxProps } from 'components/Box';
import ChevronIcon from 'components/icons/ChevronIcon';
import Flex from 'components/Flex';
import Link from 'components/Link';

type Props = BoxProps & {
  label: string;
  links: {
    title: string;
    subtitle: string;
    link: string;
  }[];
};

const HeaderLinkHoverWrap: React.FC<Props> = ({ label, links }) => {
  const [active, setActive] = useState(true);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.pathname.startsWith('/employment-law')) {
        setActive(true);
      } else {
        setActive(false);
      }
    } else {
      setActive(false);
    }
  }, []);
  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      height={[40, 'auto']}
      sx={{ ':hover > .hover-show': { visibility: 'visible' } }}
    >
      <Box
        display="inline-block"
        variant="header6Serif"
        textAlign="center"
        sx={{ borderBottom: '2px solid', borderColor: active ? 'gold' : 'transparent' }}
      >
        {label}
      </Box>
      <Box
        className="hover-show"
        position="absolute"
        top="calc(100% + 20px)"
        left="50%"
        bg="white"
        p={4}
        width={300}
        sx={{
          visibility: 'hidden',
          filter: `drop-shadow(${shadows.popover})`,
          transform: 'translateX(-50%)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '-20px',
            left: 0,
            height: '20px',
            width: '100%',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '-10px',
            left: '50%',
            transform: 'translateX(-50%)',
            borderBottom: '10px solid',
            borderBottomColor: 'white',
            borderRight: '10px solid transparent',
            borderLeft: '10px solid transparent',
          },
        }}
      >
        {links.map(({ title, subtitle, link }) => (
          <Link
            key={link}
            to={link}
            noDecorate
            display="grid"
            sx={{
              gridTemplateColumns: '1fr min-content',
              gridTemplateRows: 'min-content min-content',
              gridTemplateAreas: `"title icon" "subtitle icon"`,
              gridRowGap: 2,
              gridColumnGap: 2,
              alignItems: 'center',
              ':not(:first-child)': { mt: 3 },
              '&:hover span': { borderColor: 'gold' },
            }}
          >
            <Box variant="header6Bold">
              <Box as="span" borderBottom="2px solid" borderColor="transparent">
                {title}
              </Box>
            </Box>
            <Box
              variant="body2"
              gridArea="subtitle"
              display="inline-block"
              sx={{ textDecoration: 'none' }}
            >
              {subtitle}
            </Box>
            <ChevronIcon dir="right" color="gold" gridArea="icon" />
          </Link>
        ))}
      </Box>
    </Flex>
  );
};

export default HeaderLinkHoverWrap;
