import React, { useMemo } from 'react';

import Svg, { SvgProps } from './Svg';

type Props = SvgProps & {
  dir?: 'up' | 'down' | 'left' | 'right';
};

const ChevronIcon: React.FC<Props> = ({ dir = 'up', sx, ...props }) => {
  const rotationVal = useMemo(() => {
    switch (dir) {
      case 'right':
        return '-90deg';
      case 'left':
        return '90deg';
      case 'down':
        return '0deg';
      case 'up':
      default:
        return '180deg';
    }
  }, [dir]);

  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 16 9"
      fill="none"
      sx={{
        transition: 'transform 200ms ease',
        transform: `rotate(${rotationVal})`,
        ...sx,
      }}
      {...props}
    >
      <path
        d="M1 1l7 7 7-7"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ChevronIcon;
