import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { footerMinHeight } from 'components/theme/measurements';
import Box from 'components/Box';
import Flex from 'components/Flex';
import Link from 'components/Link';

const query = graphql`
  {
    sanityCompanyDetails(_id: { eq: "companyDetails" }) {
      companyName
      address {
        name
        addressLine1
        addressLine2
        city
        province
        postalCode
      }
    }
  }
`;
const Footer: React.FC = () => {
  const {
    sanityCompanyDetails: { companyName, address },
  } = useStaticQuery(query);
  return (
    <Box bg="grey900" color="white" minHeight={footerMinHeight} py={5} px={5}>
      <Flex flexDirection={['column', 'row']}>
        <Box flex={1}>
          <Box mt={1} lineHeight="1.3em" textAlign={['center', 'left']}>
            {companyName}
            <br />
            {address.name && (
              <>
                {address.name}
                <br />
              </>
            )}
            {address.addressLine1}
            {!!address.addressLine2 && `, ${address.addressLine2}`}
            <br />
            {address.city}, {address.province} {address.postalCode}
          </Box>
        </Box>
        <Box textAlign={['center', 'right']} color="lightGold" mt={[4, 0]}>
          <Link to="/privacy-policy/" ml="auto">
            Privacy Policy
          </Link>
          <Box as="span" mx={2}>
            |
          </Box>
          <Link to="/terms-of-use/">Terms of Use</Link>
          <Box mt={3}>
            © {companyName} {new Date().getFullYear()}. All Rights Reserved.
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
