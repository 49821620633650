import React from 'react';

import Box from 'components/Box';
import Link, { LinkProps } from 'components/Link';
import buttonTheme, { ButtonTheme } from 'components/theme/button-theme';

type Props = (Omit<LinkProps, 'to' | 'href'> & {
  bVariant?: ButtonTheme;
}) &
  ({ to: string; href?: never } | { to?: never; href: string });

const LinkButton: React.FC<Props> = ({ bVariant = 'primary', sx, ...props }) => {
  const { sx: buttonVariantSx = {}, ...buttonVariant } = buttonTheme[bVariant] || {};
  const combinedSx = React.useMemo(() => ({ ...buttonVariantSx, ...sx }), [buttonVariantSx, sx]);
  return props.to ? (
    <Link
      variant="block"
      lineHeight={`${buttonVariant.height}px`}
      {...buttonVariant}
      sx={combinedSx}
      {...props}
    />
  ) : (
    <Box as="a" {...buttonVariant} sx={combinedSx} {...props} />
  );
};

export default LinkButton;
