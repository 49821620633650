import React, { SVGProps } from 'react';

import Box, { BoxProps } from '../Box';

export type SvgProps = SVGProps<SVGSVGElement> & BoxProps;

const Svg: React.FC<SvgProps> = (props) => {
  return <Box as="svg" {...props} />;
};

export default Svg;
