import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const SITE_METADATA = graphql`
  query {
    sanitySiteSettings(_id: { eq: "siteSettings" }) {
      name
      title
      description
      email
      subject
      imgUrl
      logoUrl
    }
    site {
      siteMetadata {
        siteUrl
        robots
      }
    }
  }
`;

export type SEOProps = {
  title?: string | null;
  description?: string | null;
  imagePublicURL?: string | null;
  pagePath?: string;
};

const SEO: React.FC<SEOProps> = ({
  title = '',
  description = '',
  imagePublicURL = '',
  pagePath = '/',
}) => {
  const metadata = useStaticQuery(SITE_METADATA);
  const {
    name,
    title: defaultTitle,
    description: defaultDescription,
    email,
    subject,
    imgUrl,
    logoUrl,
  } = metadata.sanitySiteSettings || {};
  const { siteUrl, robots } = metadata.site.siteMetadata || {};

  const seoTitle = title ? `${title} | Perks Law Group` : defaultTitle;
  const seoDescription = description || defaultDescription;
  const pageUrl = `${siteUrl}${pagePath}`;

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'LegalService',
      '@id': siteUrl,
      url: siteUrl,
      name,
      description: seoDescription,
      logo: logoUrl,
      image: imgUrl,
      sameAs: [
        'https://twitter.com/perkslawgroup',
        'https://facebook.com/perkslawgroup',
        'https://www.linkedin.com/company/perkslawgroup/',
      ],
      address: {
        '@type': 'PostalAddress',
        streetAddress: '5700-100 King St W',
        addressLocality: 'Toronto',
        addressRegion: 'ON',
        postalCode: 'M5X 1C7',
        addressCountry: 'CA',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '43.648688',
        longitude: '-79.382063',
      },
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '08:30',
          closes: '17:30',
        },
      ],
      publicAccess: false,
      email: 'info@perkslawgroup.com',
      telephone: '+16475282560',
    },
  ];

  const sharingImage = imagePublicURL ? `${siteUrl}${imagePublicURL}` : imgUrl;
  const date = new Date().toLocaleString('en-CA', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <Helmet>
      <title lang="en">{seoTitle}</title>
      <meta name="subject" content={subject} />
      <meta name="description" content={seoDescription} />
      <meta name="language" content="EN" />
      <meta name="robots" content={robots} />
      <meta name="revised" content={date} />
      <meta name="image" content={sharingImage} />
      <meta name="summary" content={seoDescription} />
      <meta name="classification" content="Business" />
      <meta name="author" content={email} />
      <meta name="designer" content={name} />
      <meta name="copyright" content={name} />
      <meta name="reply-to" content={name} />
      <meta name="owner" content={name} />
      <meta name="url" content={siteUrl} />
      <meta name="identifier-URL" content={siteUrl} />

      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      <meta property="og:title" content={seoTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:site_name" content={name} />
      <meta property="og:image" content={sharingImage} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:email" content={email} />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@perkslawgroup" />
      <meta name="twitter:image" content={sharingImage} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />

      <link rel="canonical" href={pageUrl} />
    </Helmet>
  );
};

export default SEO;
