import React from 'react';
import styled, { css } from 'styled-components';

import Box, { BoxProps } from 'components/Box';

const sharedLineStyle = () => css`
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: currentColor;
  border-radius: 1px;
`;

type Props = BoxProps & { active: boolean };
const HamburgerInner = styled.div<Props>`
  ${sharedLineStyle}
  left: 1px;
  top: 16px;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
  ${(props) =>
    props.active &&
    'transition-delay: .12s; transition-timing-function: cubic-bezier(.215,.61,.355,1); transform: rotate(45deg);'}

  &::before {
    ${sharedLineStyle}
    content: "";
    transition: top 75ms ease 0.12s, opacity 75ms ease;
    top: -10px;
    ${(props) =>
      props.active && 'top: 0; transition: top 75ms ease,opacity 75ms ease .12s; opacity: 0;'}
  }
  &::after {
    ${sharedLineStyle}
    content: "";
    bottom: -10px;
    transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    ${(props) =>
      props.active &&
      'bottom: 0; transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s; transform: rotate(-90deg);'}
  }
`;

const Hamburger: React.FC<Props> = ({ active, ...props }) => (
  <Box position="relative" size={32} color="black" {...props}>
    <HamburgerInner active={active} />
  </Box>
);

export default Hamburger;
