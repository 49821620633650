import React from 'react';

export const Facebook: React.FC = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.17838 2.26592C1.17838 2.56076 1.17838 3.87678 1.17838 3.87678H0V5.84654H1.17838V11.7H3.59904V5.84671H5.22341C5.22341 5.84671 5.37554 4.90222 5.44928 3.86952C5.23786 3.86952 3.60819 3.86952 3.60819 3.86952C3.60819 3.86952 3.60819 2.72357 3.60819 2.52271C3.60819 2.32141 3.87211 2.05064 4.13297 2.05064C4.39334 2.05064 4.94286 2.05064 5.45183 2.05064C5.45183 1.78245 5.45183 0.855809 5.45183 7.03627e-09C4.77237 7.03627e-09 3.99936 7.03627e-09 3.65863 7.03627e-09C1.11855 -0.000136004 1.17838 1.97159 1.17838 2.26592Z"
        fill="currentColor"
      />
    </svg>
  );
};
export const LinkedIn: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.46462 3.37932H0.134164V10.4H2.46462V3.37932Z" fill="currentColor" />
      <path
        d="M10.1128 4.02061C9.62329 3.48453 8.97582 3.21644 8.17081 3.21644C7.8742 3.21644 7.60459 3.25304 7.36209 3.32629C7.11966 3.3995 6.9149 3.5022 6.74771 3.63446C6.58062 3.76672 6.44755 3.88949 6.34876 4.00279C6.25481 4.11041 6.16294 4.23577 6.0733 4.37758V3.37927H3.74979L3.75692 3.71937C3.76169 3.94614 3.76404 4.6451 3.76404 5.81634C3.76404 6.98768 3.75934 8.51551 3.74989 10.4H6.0733V6.48222C6.0733 6.2414 6.09905 6.05016 6.15095 5.90835C6.24989 5.66736 6.39922 5.46559 6.59942 5.30272C6.79961 5.13964 7.04787 5.05818 7.34457 5.05818C7.74934 5.05818 8.04723 5.19867 8.23794 5.47969C8.42857 5.76068 8.5239 6.14917 8.5239 6.6451V10.3998H10.8473V6.37606C10.8471 5.34162 10.6025 4.55651 10.1128 4.02061Z"
        fill="currentColor"
      />
      <path
        d="M1.31352 0C0.922799 0 0.606212 0.114615 0.363683 0.343622C0.121178 0.572679 0 0.861859 0 1.21144C0 1.55613 0.11769 1.84431 0.353096 2.07568C0.588428 2.30707 0.899202 2.42282 1.28525 2.42282H1.29937C1.69489 2.42282 2.0139 2.30717 2.25633 2.07568C2.49876 1.84431 2.61761 1.55622 2.61294 1.21144C2.60824 0.861884 2.4881 0.572679 2.25277 0.343622C2.01746 0.114516 1.70426 0 1.31352 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
export const Twitter: React.FC = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8304 1.2312C12.3533 1.44 11.8449 1.5784 11.3148 1.6456C11.8601 1.3208 12.2763 0.8104 12.472 0.1952C11.9636 0.4976 11.4022 0.7112 10.804 0.8304C10.3213 0.3176 9.63325 0 8.88267 0C7.42642 0 6.25403 1.1792 6.25403 2.6248C6.25403 2.8328 6.27168 3.0328 6.31498 3.2232C4.12819 3.1168 2.1932 2.0712 0.893319 0.4784C0.666381 0.8712 0.533265 1.3208 0.533265 1.8048C0.533265 2.7136 1.00238 3.5192 1.70164 3.9856C1.27903 3.9776 0.864451 3.8552 0.513217 3.6624C0.513217 3.6704 0.513217 3.6808 0.513217 3.6912C0.513217 4.9664 1.42498 6.0256 2.62062 6.2696C2.40651 6.328 2.17315 6.356 1.93098 6.356C1.76258 6.356 1.59258 6.3464 1.433 6.3112C1.77381 7.3504 2.7409 8.1144 3.89083 8.1392C2.99591 8.8376 1.85961 9.2584 0.629493 9.2584C0.413782 9.2584 0.206891 9.2488 0 9.2224C1.16516 9.972 2.54604 10.4 4.03517 10.4C8.87545 10.4 11.5217 6.4 11.5217 2.9328C11.5217 2.8168 11.5177 2.7048 11.5121 2.5936C12.0341 2.224 12.4728 1.7624 12.8304 1.2312Z"
        fill="currentColor"
      />
    </svg>
  );
};
