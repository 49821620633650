import React from 'react';

import { headerHeight, mobileHeaderHeight } from 'components/theme/measurements';
import Box from 'components/Box';
import Footer from 'components/layout/Footer';
import Header from 'components/layout/Header';
import SEO, { SEOProps } from 'components/layout/SEO';

type Props = {
  seoProps?: SEOProps;
};

class MainLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleFirstTab = this.handleFirstTab.bind(this);
    this.handleMouseDownOnce = this.handleMouseDownOnce.bind(this);
  }

  componentDidMount(): void {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', this.handleFirstTab);
    }
  }

  handleFirstTab(e: KeyboardEvent): void {
    if (typeof window !== 'undefined' && e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing');

      window.removeEventListener('keydown', this.handleFirstTab);
      window.addEventListener('mousedown', this.handleMouseDownOnce);
    }
  }

  handleMouseDownOnce(): void {
    if (typeof window !== 'undefined') {
      document.body.classList.remove('user-is-tabbing');

      window.removeEventListener('mousedown', this.handleMouseDownOnce);
      window.addEventListener('keydown', this.handleFirstTab);
    }
  }

  render(): React.ReactNode {
    const { children, seoProps } = this.props;
    return (
      <Box>
        <SEO {...seoProps} />
        <Header />
        <Box as="main" pt={[mobileHeaderHeight, mobileHeaderHeight, headerHeight]} variant="body1">
          {children}
        </Box>
        <Footer />
      </Box>
    );
  }
}

export default MainLayout;
